
import { createSignal, createEffect,   Switch as SolidSwitch, Match, onCleanup,  } from "solid-js";
import { Stack, Menu,  ListItemIcon, ListItemText, TextField, MenuItem,  } from "@suid/material";
import { getText } from '../SmallComponents/Translate'
import { CountryType, countries } from "../DataLists/DataCountryList";

interface CountrySelectProps {
  id: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  type: 'Country' | 'Language' | 'MobilePreFix';
  valueOnChange: (value: string) => void;
  defaultValue?: string;
}

const CountrySelect = ({
  id,
  label,
  type,
  valueOnChange,
  defaultValue,
}: CountrySelectProps) =>{

  const defaultCountry = countries.find(country => country.code === defaultValue) || { code: 'DK', label: 'Denmark', phone: '45' };
  const defaultLanguage = countries.find(country => country.languageCode === defaultValue) || { languageCode: 'en-GB', code: 'GB', languageLabel: 'English (UK)', label: 'English (UK)', phone: '44' };
  const txt1 = getText("smallcomponent", "choose")
  const localLabel = (txt1() as string) + { label }
  //const options: readonly CountryType[] = countries.find(country => country?.languageLabel > "") ;
  const options: readonly CountryType[] = countries

  // autoHighlight
  const ITEM_HEIGHT = 48;
  let inputElement = () => document.createElement("dummyinput");

  const [selectedIndex, setSelectedIndex] = createSignal(1);
  const [inputLabel, setInputLabel] = createSignal(type==="Language" ? defaultLanguage.languageLabel : defaultCountry.label);
  const [inputCode, setInputCode] = createSignal(type==="Language" ? defaultLanguage.languageCode : defaultCountry.code);
  const [filteredOptions, setFilteredOptions] = createSignal<CountryType[]>([]);
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());
  const handleClose = () => setAnchorEl(null);

  const handleChange = (event: Event) => {
    const newValue = (event.target as HTMLInputElement).value;
    const useCode = newValue.substring(0, 3).includes("(")

    setInputLabel(newValue);

   // if (newValue.length >= (!useCode ? 2 : 3)) {
    if (newValue.length >= 1) {
      // Open the menu when user has entered 3 or more characters
      setAnchorEl(event.target as HTMLElement);

      const newFilteredLabelOptions = options.filter((option) =>
        option.label.toLowerCase().includes(newValue.toLowerCase())
      );

      const defaultCode = countries.find(country => country.label === newValue)
      if (defaultCode)
        setInputCode(defaultCode!.code);

    } else {
      // Close the menu when user has entered less than 3 characters
      handleClose();
      setInputCode("");
    }
  };

  const handleMenuSelected = (index: number) => {
    setSelectedIndex(index);
    const tmpCode = type === "Language" && filteredOptions()[index]?.languageCode ? filteredOptions()[index]?.languageCode as string : filteredOptions()[index].code
    const tmpLabel = type === "Language" && filteredOptions()[index]?.languageLabel ? filteredOptions()[index]?.languageLabel as string : filteredOptions()[index].label
    setInputCode(tmpCode)
    setInputLabel(tmpLabel)
    valueOnChange(tmpCode)
    setAnchorEl(null);
  };

  // Listen for changes in the input value
  createEffect(() => {
    let newInputValue = inputLabel();
    if (!newInputValue) return;
    const usePhone = newInputValue.substring(0, 3).includes("+")
    const useCode = newInputValue.substring(0, 3).includes("(")

    // Remove the + or ( charater in the input field to enable search)
    if (usePhone || useCode) {
      newInputValue = newInputValue.substring(1, 3)
    }

    const newFilteredLabelOptions = options.filter((option) =>
      option.label.toLowerCase().includes(newInputValue!.toLowerCase())
    );
    const newFilteredCodeOptions = options.filter((option) =>
      option.code.toLowerCase().includes(newInputValue!.toLowerCase())
    );
    const newFilteredPhoneOptions = options.filter((option) =>
      option.phone.toLowerCase().includes(newInputValue!.toLowerCase())
    );

    if (type === "Language") {
      const newFilteredLanguageOptions = options.filter((option) =>
        option.languageLabel?.toLowerCase().includes(newInputValue!.toLowerCase())
      );
      setFilteredOptions(newFilteredLanguageOptions);
    } else {
      setFilteredOptions(usePhone ? newFilteredPhoneOptions : (useCode ? newFilteredCodeOptions : newFilteredLabelOptions));
    }
  });

  createEffect(() => {
    const anchor = anchorEl();
    if (anchor) {
      onCleanup(() => {
        inputElement!()?.focus();

      });
    }
  });
 
   
  // InputProps={{
  //   startAdornment: (
  //     <IconButton
  //       aria-label="more"
  //       id={"long-button" + id}

  //       aria-controls={open() ? "long-menu" : undefined}
  //       aria-expanded={open() ? "true" : undefined}
  //       aria-haspopup="true"
  //       onClick={(event) => { setInputLabel(""); setInputCode(""); setAnchorEl(event.currentTarget) }}
  //     >
  //       {type === 'MobilePreFix' ? <PhoneIcon /> : (type === 'Language' ? <LanguageIcon /> : <CountryIcon />)}
  //     </IconButton>
  //   ),
  // }}

  return (
    <>
      <Stack direction="row" alignItems="left" >

        <TextField
          required
          fullWidth
          id={id}
          type="search"
          size="small"
          label={label}
          variant="outlined"
          value={inputLabel()}
          defaultValue={inputLabel()}
          onChange={handleChange}
          ref={inputElement}
        />

        <Menu
          onChange={(e) => setInputLabel((e.target as HTMLInputElement).value)}
          id={"long-menu-country" + { id }}
          MenuListProps={{ "aria-labelledby": "long-button"+ id }}
          autoFocus={false}
          anchorEl={anchorEl()}
          open={open()}
          onClose={handleClose}
          PaperProps={{
            sx: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "40ch",
            },
          }}
        >

          {filteredOptions().map((option, index) => (
            <SolidSwitch  >
              <Match when={type === 'Language' && option.languageLabel}>
                <MenuItem
                  value={type === 'Language' && option.languageCode ? option.languageCode?.toLowerCase() : option.code.toLowerCase()}
                  onClick={() => {
                    handleMenuSelected(index);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.flagCode?.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.flagCode?.toLowerCase()}.png 2x`}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText>

                    {option.languageLabel} ({option.languageCode})
                  </ListItemText>
                </MenuItem>
              </Match>
              <Match when={type === 'Country'}>
                <MenuItem
                  value={type === 'Language' && option.languageCode ? option.languageCode?.toLowerCase() : option.code.toLowerCase()}
                  onClick={() => {
                    handleMenuSelected(index);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                  </ListItemIcon>
                  <ListItemText>
                    {option.label} ({option.code}) +{option.phone}

                  </ListItemText>
                </MenuItem>
              </Match>
            </SolidSwitch>
          ))}
        </Menu>
      </Stack>
    </>
  );
}

export { CountrySelect }